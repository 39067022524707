<template>
  <ion-page>
    <ion-header class="ion-text-center">
      <ion-toolbar>
        <ion-title>
          Parametri di ricerca
        </ion-title>
        <ion-button @click="close()" color="primary" fill="clear" slot="end">
          <ion-icon icon="close" />
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <ion-fab slot="fixed" vertical="bottom" horizontal="end">
        <ion-fab-button color="secondary" @click="search()">
          <ion-icon name="play" />
        </ion-fab-button>
      </ion-fab>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-input type="search" v-model="optText">
              <ion-icon name="search" class="ion-margin-start" />
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-radio-group v-model="optType">
              <ion-grid>
                <ion-row>
                  <ion-col v-for="qt of tipiQuery" :key="qt.value" size="4" class="ion-no-padding">
                    <ion-item lines="none">
                      <ion-label class="ion-text-center">{{
                        qt.label
                      }}</ion-label>
                      <ion-radio :value="qt.value"></ion-radio>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-radio-group>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Ricerca sul massimario ufficiale della Cassazione</ion-label>
              <ion-checkbox v-model="optSearchMassimeUfficiali">
              </ion-checkbox>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Tipi Massima</ion-label>
              <ion-select v-model="optTipiMassima" multiple="true" cancel-text="Annulla">
                <ion-select-option v-for="m of tipiMassima" :key="m.value" :value="m.value">{{ m.label
                }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Aree Tematiche</ion-label>
              <ion-select v-model="optAreeTematiche" interface="popover" @ionChange="changeAreaTematica" multiple="true"
                cancel-text="Annulla" :disabled="optSearchMassimeUfficiali">
                <ion-select-option v-for="a of areeTematiche" :key="a.value" :value="a.value">{{ a.label
                }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Materie</ion-label>
              <ion-select v-model="optMaterie" interface="popover" @ionChange="changeMateria" multiple="true"
                cancel-text="Annulla" :disabled="optSearchMassimeUfficiali">
                <ion-select-option v-for="m of materie" :key="m.value" :value="m.value">{{ m.label }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Argomenti</ion-label>
              <ion-select v-model="optArgomenti" interface="popover" multiple="true" cancel-text="Annulla"
                :disabled="argomenti.length == 0 || optSearchMassimeUfficiali">
                <ion-select-option v-for="a of argomenti" :key="a.value" :value="a.value" lines="none">{{ a.label
                }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Ordina per</ion-label>
              <ion-select v-model="optTipoOrdinamento" interface="popover" lines="none">
                <ion-select-option v-for="o of tipiOrdinamento" :key="o.value" :value="o.value">{{ o.label
                }}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { Picklist } from '../../models/picklist.model';
import { document, close, play } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { JnService } from '../../services/jn.service';
import { EsService } from '../../services/es.service';
import { Utils } from '../../services/utils';
import {
  QueryTypes,
  OrderingTypes,
  CategoriesMassimario,
  CategoriesJuranet
} from '../../models/enums.model';

addIcons({
  document: document,
  close: close,
  play: play,
});

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonIcon,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonButton,
  modalController,
  IonFab,
  IonFabButton,
  IonCheckbox,
} from '@ionic/vue';
import { SearchData } from '@/models/search.model';

export default defineComponent({
  name: 'JnMassimarioSearchParams',
  props: {
    searchData: {
      type: SearchData,
      required: true,
    },
  },
  components: {
    IonContent,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    // IonItemDivider,
    IonIcon,
    IonInput,
    IonToolbar,
    IonHeader,
    IonPage,
    IonTitle,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonFab,
    IonFabButton,
    IonCheckbox
  },

  setup(props) {

    const optSearchMassimeUfficiali = ref(false)
    const optTipiMassima = ref([] as Array<string>);
    const optAreeTematiche = ref([] as Array<string>);
    const optMaterie = ref([] as Array<string>);
    const optArgomenti = ref([] as Array<string>);
    const optTags = ref([] as Array<string>);
    const optTipoDocumento = ref([] as Array<string>);
    const optText = ref('');
    const optType = ref(QueryTypes.OR);
    const optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;

    const tipiQuery = ref([] as Array<Picklist>);
    const tipiOrdinamento = ref([] as Array<Picklist>);

    const tipiMassima = ref([] as Array<Picklist>);
    const materie = ref([] as Array<Picklist>);
    const categorie = ref([] as Array<Picklist>);
    const argomenti = ref([] as Array<Picklist>);
    const areeTematiche = ref([] as Array<Picklist>);
    const tags = ref([] as Array<Picklist>);

    const searchData = ref(props.searchData);

    let areeTematicheRaw!: any;
    let tagsRaw!: any;

    onMounted(async () => {
      tipiQuery.value = JnService.getQueryTypes();
      tipiOrdinamento.value = JnService.getTipiOrdinamento();

      /*  const massimeRaw = JSON.parse(
          await EsService.getOptions('massime')
        );
        for (const massima of massimeRaw) {
          console.log(massima)
          new Picklist({ label: massima.value, value: massima.id })
        }*/
      tipiMassima.value = await EsService.getPicklist('massime')
      console.log(tipiMassima.value)
      tipiMassima.value.sort(Utils.sort('label', 'asc'));

      const raw = JSON.parse(
        await EsService.getOptions('categorie-banca-dati-juranet')
      );

      areeTematicheRaw = JSON.parse(
        await EsService.getOptions('struttura-aree-tematiche')
      );

      for (const areaTematica of areeTematicheRaw['aree-tematiche']) {
        areeTematiche.value.push(
          new Picklist({ label: areaTematica.name, value: areaTematica.id })
        );
        areeTematiche.value.sort(Utils.sort('label', 'asc'));

        /*for (const materia of areaTematica.materie) {
          materie.value.push(
            new Picklist({ label: materia.name, value: materia.id })
          );
        }
        materie.value.sort(Utils.sort('label', 'asc'));*/
      }
      tagsRaw = JSON.parse(await EsService.getOptions('tags'));
      for (const tag of tagsRaw) {
        if (tag.value != 'ROOT') {
          tags.value.push(new Picklist({ value: tag.id, label: tag.value }));
        }
      }
    });

    const search = async () => {
      searchData.value.keywords = optText.value;
      searchData.value.queryType = optType.value;
      if (optTipoOrdinamento) {
        searchData.value.orderingType = optTipoOrdinamento;
      }
      const tipiMassima: string[] = [];
      for (const td of optTipiMassima.value) {
        tipiMassima.push("999999" + td);
      }
      if (tipiMassima.length == 0) {
        searchData.value.idCategory = CategoriesMassimario;
      } else {
        searchData.value.idCategory = tipiMassima;
      }

      if (optSearchMassimeUfficiali.value) {
        searchData.value.index = process.env.VUE_APP_JURANET_INDEXES!;
        searchData.value.searchInMassime = optSearchMassimeUfficiali.value;
        searchData.value.idCategory = [CategoriesJuranet.DCCASS];
      }

      const idAreeTematiche: string[] = [];
      for (const m of optAreeTematiche.value) {
        idAreeTematiche.push(m);
      }
      searchData.value.idAreaTematica = idAreeTematiche;

      const idMaterie: string[] = [];
      for (const m of optMaterie.value) {
        idMaterie.push(m);
      }
      searchData.value.idMateria = idMaterie;

      const idArgomenti: string[] = [];
      for (const a of optArgomenti.value) {
        idArgomenti.push(a);
      }
      searchData.value.idArgomento = idArgomenti;

      const tags: string[] = [];
      for (const t of optTags.value) {
        tags.push(t);
      }
      searchData.value.tags = tags;

      modalController.dismiss(searchData);
    };

    const close = async () => {
      modalController.dismiss(undefined);
    };
    /*
        const clear = async () => {
          optText.value = '';
          optType.value = QueryTypes.OR;
          optMaterie.value = [];
          optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;
          optArgomenti.value = [];
          optTags.value = [];
          optTipoDocumento.value = [];
        };
    **/
    const changeAreaTematica = async () => {
      argomenti.value = [];
      optArgomenti.value = [];
      materie.value = []
      optMaterie.value = []
      for (const areaTematica of areeTematicheRaw['aree-tematiche']) {
        for (const selectedAreaTematica of optAreeTematiche.value) {
          if (selectedAreaTematica == areaTematica.id) {
            for (const materia of areaTematica.materie) {
              materie.value.push(new Picklist({ label: materia.name, value: materia.id }))
            }
          }
        }
      }
    };

    const changeMateria = async () => {
      argomenti.value = [];
      optArgomenti.value = [];
      for (const selectedMateria of optMaterie.value) {
        for (const areaTematica of areeTematicheRaw['aree-tematiche']) {
          for (const materia of areaTematica.materie) {
            if (materia.id == selectedMateria) {
              for (const argomento of materia.argomenti) {
                argomenti.value.push(
                  new Picklist({ label: argomento.name, value: argomento.id })
                );
              }
            }
          }
        }
      }
    };

    return {
      tipiQuery,
      optArgomenti,
      optSearchMassimeUfficiali,
      optTipiMassima,
      optAreeTematiche,
      optMaterie,
      optText,
      optType,
      optTipoOrdinamento,
      tipiOrdinamento,
      categorie,
      changeAreaTematica,
      changeMateria,
      tipiMassima,
      areeTematiche,
      materie,
      argomenti,
      search,
      close,
    };
  },
});
</script>
